$fa-font-path: "../webfonts";

@import "bootstrap";
@import "github";
@import "playfair-display";
@import "fontawesome";
@import "solid";
@import "brands";

main { padding-top: 20px; }

h1, h2, h3, h4, h5, h6 {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.navbar {
    .navbar-brand {
        height: 50px;
        line-height: 1;
        font-size: 2.25rem;
        font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .navbar-text {
        a { 
            text-decoration: none;
            padding-right: $spacer;
        }

        a:hover { 
            color: theme-color("dark");

            i { @extend .fa-spin; }
        }
    }
}

.jumbotron a { color: $white; }

.card-title a { 
    color: $body_color; 
    text-decoration: none; 
}

.card:hover { @extend .shadow; }

.highlight {

    pre { 
        background: theme-color("light");
        border-radius: 3px;
        white-space: pre-wrap;
        padding: 5px;
        margin-bottom: 1rem; 
        @extend .shadow-sm;
    }

    table pre {
        @extend .shadow-none;
        margin-bottom: 0;
    }

    .lineno {
        border-right: 1px solid;
    }
}

.poem {
    div.highlight {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    pre {
        font-family: "Playfair Display", Georgia, "Times New Roman", serif;
        font-size: 1em;
        background: none;
        border-radius: 10px;
        padding: 20px 10px;
        @extend .shadow;
     }
}

.post { margin-bottom: 20px; }

.post-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;

    div {
        flex: 1 1 0;
        width: 45%;
    }

    div:first-child {
        padding-right: 0.5em;
    }
    
    div:last-child {
        padding-left: 0.5em;
        text-align: right;
    }

    a {
        color: $dark;
        text-decoration: none;

        &:hover {
            color: $gray-600;
        }
    }
}

blockquote {
    @extend .blockquote;
    border-left: 10px solid theme-color("dark");
    border-radius: 5px;
    background: theme-color("light");
    margin: 10px 20px;
    padding: 10px 20px 10px;
    @extend .shadow-sm;

    p:last-child { margin-bottom: 0; }
}

.blog-footer {
    padding: 2.5rem 0;
    color: #999;
    text-align: center;
    background-color: #f9f9f9;
    border-top: .05rem solid #e5e5e5;
  }
